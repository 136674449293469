// import { chineseReg } from '@/utils/validation'
// const chineseValidation = {
//   required: true, pattern: /^[^\u4e00-\u9fa5]+$/, message: 'Input English only', trigger: 'blur'
// }
const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'change' }

export default {
  source_of_funds_text: [baseRequirement],
  purpose_of_transaction_text: [baseRequirement],
  transaction_country_list: [
    baseRequirement
  ],
  num_of_anual_transactions: [baseRequirement],
  frequency_of_transaction: [
    baseRequirement
  ],
  total_volumn_of_trasaction_per_year: [baseRequirement],
  average_volume_of_per_transaction: [baseRequirement],
  is_money_from_high_risk_country: [baseRequirement],
  money_from_high_risk_country_specific: [baseRequirement],
  had_trade_with_high_rish_country: [baseRequirement],
  trade_with_high_rish_country_specific: [baseRequirement],
  is_pay_for_thrid_party: [baseRequirement],
  pay_for_thrid_party_specific: [baseRequirement]
}
